<template>
  <v-dialog
    v-model="active"
    persistent
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar
        color="white"
        style="border-radius: 0 !important;">
        <v-toolbar-title style="width: 100%;">
          <div class="d-flex flex-row justify-space-between align-center">
            <v-btn
              class="mr-2"
              color="error"
              icon
              @click="cancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <span>
              RFID Scanner
            </span>
            <v-btn
              color="success"
              text
              @click="confirm()">
              ยืนยัน
            </v-btn>
          </div>
        </v-toolbar-title>
      </v-toolbar>
      <v-container fluid>
        <p
          class="text-bold text-right mt-4">
          นับได้ / ทั้งหมด : <span :class="{ 'red--text': (totalCount !== totalQty) }">{{ totalCount }}</span> / {{ totalQty }}
        </p>
        <v-data-table
          :headers="headers"
          :items="scannedItems"
          :item-per-page="-1"
          hide-default-footer
          class="elevation-3">
          <template #[`item.code`]="{ item }">
            <span :class="{ 'red--text': isError(item) }">
              {{ item.code || item.values[0] }}
            </span>
          </template>
          <template #[`item.qty`]="{ item }">
            <span :class="{ 'red--text': isError(item) }">
              {{ item.qty | showNumberFormat() }}
            </span>
          </template>
          <template #[`item.counted`]="{ item }">
            <span :class="{ 'red--text': isError(item) }">
              {{ item.counted | showNumberFormat() }}
            </span>
          </template>
          <template #[`item.count`]="{ item }">
            <span :class="{ 'red--text': isError(item) }">
              {{ item.count | showNumberFormat() }}
            </span>
          </template>
          <template #[`item.action`]="{ index }">
            <gw-icon-hover
              class="sku-table-bin"
              icon-name="mdi-delete"
              icon-hover="mdi-delete-empty"
              small
              color="error"
              @click="removeItem(index)" />
          </template>
        </v-data-table>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import ProductProvider from '@/resources/ProductProvider'

const ProductService = new ProductProvider()

export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    compare: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => []
    },
    aliasIds: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      scanText: '',
      scannedRFID: [],
      scannedItems: [],
      notFoundItems: [],
      masterSkus: {}
    }
  },
  computed: {
    active: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    skus () {
      return this.items.reduce((arr, i) => [...arr, ...i.skus], [])
    },
    headers () {
      if (this.compare) {
        return [
          {
            text: 'รหัสสินค้า',
            value: 'code',
            sortable: false,
            class: 'vertical-middle',
            cellClass: 'vertical-middle'
          },
          {
            text: 'จำนวน',
            value: 'qty',
            sortable: false,
            align: 'right',
            class: 'vertical-middle',
            cellClass: 'vertical-middle'
          },
          {
            text: 'นับแล้ว',
            value: 'counted',
            sortable: false,
            align: 'right',
            class: 'vertical-middle',
            cellClass: 'vertical-middle'
          },
          {
            text: 'นับได้',
            value: 'count',
            sortable: false,
            align: 'right',
            class: 'vertical-middle',
            cellClass: 'vertical-middle'
          },
          {
            text: '',
            value: 'action',
            align: 'center',
            sortable: false,
            class: 'vertical-middle',
            cellClass: 'vertical-middle'
          }
        ]
      }

      return [
        {
          text: 'รหัสสินค้า',
          value: 'code',
          sortable: false,
          class: 'vertical-middle',
          cellClass: 'vertical-middle'
        },
        {
          text: 'นับแล้ว',
          value: 'counted',
          sortable: false,
          align: 'right',
          class: 'vertical-middle',
          cellClass: 'vertical-middle'
        },
        {
          text: 'นับได้',
          value: 'count',
          sortable: false,
          align: 'right',
          class: 'vertical-middle',
          cellClass: 'vertical-middle'
        },
        {
          text: '',
          value: 'action',
          align: 'center',
          sortable: false,
          class: 'vertical-middle',
          cellClass: 'vertical-middle'
        }
      ]
    },
    totalQty () {
      return this.skus.reduce((acc, cur) => acc + cur.amount, 0)
    },
    totalCount () {
      return this.skus.reduce((acc, cur) => acc + cur.count, 0) + this.scannedItems.reduce((acc, cur) => acc + (cur.exists ? cur.count : 0), 0)
    }
  },
  watch: {
    active (val) {
      if (val && Object.keys(this.aliasIds).length && !Object.keys(this.masterSkus).length) {
        this.masterSkus = { ...this.aliasIds }
      }
    }
  },
  mounted () {
    document.addEventListener('keydown', this.onKeydown)
    document.addEventListener('paste', this.onPaste)
  },
  beforeDestroy () {
    document.removeEventListener('keydown', this.onKeydown)
    document.removeEventListener('paste', this.onPaste)
  },
  methods: {
    isError (item) {
      return !item.exists || (this.compare && item.count > (item.qty - item.counted))
    },
    onPaste (event) {
      if (!this.active) {
        return
      }

      const clipboardData = event.clipboardData || window.clipboardData
      const pastedData = clipboardData.getData('text')

      this.onScan(pastedData)
    },
    onKeydown (text) {
      if (!this.active) {
        return
      }

      if (text.key === 'Unidentified' || text.key === 'Shift') {
        return
      }

      if (text.key === 'Enter' && this.scanText) {
        this.onScan(this.scanText)
        this.scanText = ''
      } else if (text.key !== 'Enter') {
        this.scanText += text.key
      }
    },
    hexToDecimal (val) {
      return parseInt(val, 16)
    },
    async getSkuCodeByAliasId (aliasId) {
      try {
        const { data } = await ProductService.getSkuCodeByAliasId(aliasId)
        if (data) {
          this.masterSkus[aliasId] = data[aliasId]

          const notFoundIndex = this.notFoundItems.findIndex((v) => v.aliasId === aliasId)

          if (notFoundIndex !== -1) {
            this.notFoundItems[notFoundIndex].code = data[aliasId]
          }

          const index = this.scannedItems.findIndex((v) => v.aliasId === aliasId)

          if (index !== -1) {
            this.scannedItems[index].code = data[aliasId]
          }
        }
      } catch (error) {
        console.error('getSkuCodeByAliasId', error)
      }
    },
    async onScan (val) {
      if (
        val.length !== 24
        || this.scannedRFID.includes(val)
        || this.scannedItems.some((v) => v.values.includes(val))
      ) {
        return
      }

      try {
        const aliasId = this.hexToDecimal(val.slice(3, 10))
        const isFetched = aliasId in this.masterSkus
        const index = this.scannedItems.findIndex((v) => v.aliasId === aliasId)

        if (isFetched && index !== -1) {
          this.scannedItems[index].values.push(val)
          this.scannedItems[index].count += 1

          return
        }

        if (!isFetched) {
          const notFoundIndex = this.notFoundItems.findIndex((v) => v.aliasId === aliasId)

          if (notFoundIndex === -1) {
            this.notFoundItems.push({
              values: [val],
              aliasId,
              code: null,
              exists: false,
              qty: 0,
              counted: 0,
              count: 1
            })

            this.masterSkus[aliasId] = null

            this.getSkuCodeByAliasId(aliasId)
          } else {
            this.notFoundItems[notFoundIndex].values.push(val)
            this.notFoundItems[notFoundIndex].count += 1
          }
        }

        if (this.compare) {
          const sku = this.skus.find((v) => v.code === this.masterSkus[aliasId])

          this.scannedItems.push({
            values: [val],
            aliasId: Number(aliasId),
            code: this.masterSkus[aliasId],
            exists: Boolean(sku),
            qty: sku?.maxAmount ?? sku?.amount ?? 0,
            counted: sku?.count || 0,
            count: 1
          })
        } else {
          const sku = this.skus.find((v) => v.code === this.masterSkus[aliasId])

          this.scannedItems.push({
            values: [val],
            aliasId: Number(aliasId),
            code: this.masterSkus[aliasId],
            exists: true,
            qty: 1,
            counted: sku?.amount ?? 0,
            count: 1
          })
        }
        this.scannedItems.sort((a, b) => b.exists - a.exists)
      } catch (error) {
        console.error('onScan', error)
      }
    },
    removeItem (index) {
      this.scannedItems.splice(index, 1)
    },
    confirm () {
      this.active = false
      this.$emit('confirm', this.scannedItems.filter((v) => v.exists))

      const rfids = this.scannedItems
        .filter((v) => v.exists)
        .reduce((arr, v) => [...arr, ...v.values], [])
      this.scannedRFID.push(...rfids)
      this.scannedItems = []
    },
    cancel () {
      this.active = false
      this.$emit('cancel')
    }
  }
}
</script>

<style>
.vertical-middle {
  vertical-align: middle !important;
}
</style>
