var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('v-card',[_c('v-toolbar',{staticStyle:{"border-radius":"0 !important"},attrs:{"color":"white"}},[_c('v-toolbar-title',{staticStyle:{"width":"100%"}},[_c('div',{staticClass:"d-flex flex-row justify-space-between align-center"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error","icon":""},on:{"click":function($event){return _vm.cancel()}}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('span',[_vm._v(" RFID Scanner ")]),_c('v-btn',{attrs:{"color":"success","text":""},on:{"click":function($event){return _vm.confirm()}}},[_vm._v(" ยืนยัน ")])],1)])],1),_c('v-container',{attrs:{"fluid":""}},[_c('p',{staticClass:"text-bold text-right mt-4"},[_vm._v(" นับได้ / ทั้งหมด : "),_c('span',{class:{ 'red--text': (_vm.totalCount !== _vm.totalQty) }},[_vm._v(_vm._s(_vm.totalCount))]),_vm._v(" / "+_vm._s(_vm.totalQty)+" ")]),_c('v-data-table',{staticClass:"elevation-3",attrs:{"headers":_vm.headers,"items":_vm.scannedItems,"item-per-page":-1,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.code",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': _vm.isError(item) }},[_vm._v(" "+_vm._s(item.code || item.values[0])+" ")])]}},{key:"item.qty",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': _vm.isError(item) }},[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.qty))+" ")])]}},{key:"item.counted",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': _vm.isError(item) }},[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.counted))+" ")])]}},{key:"item.count",fn:function(ref){
var item = ref.item;
return [_c('span',{class:{ 'red--text': _vm.isError(item) }},[_vm._v(" "+_vm._s(_vm._f("showNumberFormat")(item.count))+" ")])]}},{key:"item.action",fn:function(ref){
var index = ref.index;
return [_c('gw-icon-hover',{staticClass:"sku-table-bin",attrs:{"icon-name":"mdi-delete","icon-hover":"mdi-delete-empty","small":"","color":"error"},on:{"click":function($event){return _vm.removeItem(index)}}})]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }