<template>
  <v-navigation-drawer
    :value="isStatus"
    :width="350"
    stateless
    app>
    <v-container>
      <v-row class="title-sidebar">
        <v-col :cols="12">
          <slot name="header">
          </slot>
        </v-col>

        <v-col
          :cols="12"
          class="d-flex align-center col-search">
          <v-text-field
            v-model="search"
            class="search-input"
            :height="28"
            hide-details
            dense
            solo
            @input="searchProduct(true)">
            <template v-slot:prepend-inner>
              <v-chip
                v-for="(tagSelected, index) in tagsSelected"
                :key="`tag-selected-chip-${index}`"
                class="ma-1 tag-chip"
                color="teal"
                text-color="white"
                x-small
                close
                @click:close="unSelectTag(index)">
                {{ tagSelected }}
              </v-chip>
              <v-divider
                v-if="tagsSelected.length > 0"
                vertical />
            </template>
            <template v-slot:append>
              <v-icon x-small>
                mdi-magnify
              </v-icon>
            </template>
          </v-text-field>
          <v-btn
            class="ml-1"
            :min-width="0"
            :width="28"
            tile
            small
            @click="toggleTagsOverlay()">
            <v-icon x-small>
              {{ iconTags }}
            </v-icon>
          </v-btn>
          <v-fade-transition mode="out-in">
            <div
              v-if="tagsOverlay"
              class="tags-overlay elevation-5">
              <div class="tags-overlay-head pa-2">
                <div class="tags-title">
                  <span class="text-caption">
                    Filter by tags
                  </span>
                </div>
                <v-spacer />
                <div class="tags-operation">
                  <span class="text-caption mr-2">OR</span>
                  <v-switch
                    v-model="tagOperation"
                    class="ma-0"
                    false-value="OR"
                    true-value="AND"
                    color="#ffffff"
                    hide-details
                    dense
                    @change="searchProduct(true)" />
                  <span class="text-caption">AND</span>
                </div>
              </div>
              <div
                class="tags-overlay-content pa-2"
                :class="{ 'loading': loadingTags }">
                <transition-group
                  v-if="!loadingTags"
                  tag="div"
                  class="tags-group">
                  <v-chip
                    v-for="(tag, index) in tags"
                    v-show="isShowTag(tag.status)"
                    :key="`tag-chip-${index}`"
                    class="ma-1 tag-chip"
                    color="teal"
                    text-color="white"
                    x-small
                    @click="selectTag(index)">
                    {{ tag.name }}
                  </v-chip>
                </transition-group>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="teal" />
              </div>
            </div>
          </v-fade-transition>
        </v-col>
      </v-row>
      <v-row
        ref="product-note-list"
        class="product-note-list">
        <product-note
          v-for="(product) in products"
          :key="`product-${product.id}`"
          :product-id="product.id"
          :product-name="product.name"
          :model="product.model"
          :brand="product.brand"
          :variant="product.variant"
          :skus="product.skus"
          :photo-urls="product.photoUrls"
          @productSelecting="productSelecting($event)" />
        <v-col
          v-if="getting"
          class="text-center">
          <v-btn
            :loading="getting"
            fab
            text
          ></v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-navigation-drawer>
</template>

<script>
import ProductAttributeProvider from '@/resources/ProductAttributeProvider'
import ProductProvider from '@/resources/ProductProvider'
import { mapActions } from 'vuex'
import ProductNote from './ProductNote.vue'

const ProductAttributeService = new ProductAttributeProvider()
const productService = new ProductProvider()

export default {
  components: {
    productNote: ProductNote
  },
  props: {
    status: {
      type: String,
      default: 'draft'
    }
  },
  data () {
    return {
      products: [],
      tags: [],
      tagsSelected: [],
      page: 0,
      perPage: 15,
      totalPage: 1,
      totalProduct: 0,
      getting: false,
      isLoadComplete: false,
      search: '',
      searchTimeout: null,
      tagsOverlay: false,
      loadingTags: false,
      tagOperation: 'OR',
      overlay: false
    }
  },
  computed: {
    iconTags () {
      return this.tagsOverlay ? 'mdi-close' : 'mdi-tag'
    },
    isStatus () {
      return this.status === 'draft'
    }
  },
  created () {
    this.fetchProducts()
  },
  mounted () {
    this.$refs['product-note-list'].addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    this.$refs['product-note-list'].removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setErrorPage: 'Components/setErrorPage'
    }),
    toggleTagsOverlay () {
      this.tagsOverlay = !this.tagsOverlay
      this.overlay = !this.overlay
      if (this.tagsOverlay) {
        this.getTags()
      }
    },
    handleScroll (event) {
      const e = event.target
      const scrollY = e.scrollHeight - e.clientHeight - 100
      if (!this.isLoadComplete && e.scrollTop >= scrollY && !this.getting) {
        this.fetchProducts()
      }
    },
    searchProduct (isWait = false) {
      this.isLoadComplete = false
      this.page = 0
      this.getting = false

      const patternGwUrl = /https:\/\/www\.gentlewomanonline\.com/
      const patternMmUrl = /https:\/\/www\.matter-makers\.com\//
      if (patternGwUrl.test(this.search)) {
        this.search = this.search.replace('https://www.gentlewomanonline.com/', '')
      } else if (patternMmUrl.test(this.search)) {
        this.search = this.search.replace('https://www.matter-makers.com/', '')
      }

      if (isWait) {
        clearTimeout(this.searchTimeout)
        this.searchTimeout = null
        this.searchTimeout = setTimeout(() => {
          this.products = []
          this.fetchProducts()
        }, 1000)
      } else {
        this.products = []
        this.fetchProducts()
      }
    },
    async getTags () {
      try {
        this.loadingTags = true
        const { data } = await ProductAttributeService.getProductAttribute('tags', {
          page: 1,
          itemsPerPage: 9999999,
          sortBy: ['id'],
          sortDesc: [true]
        })
        this.tags = data.results.map((r) => ({
          id: r.id,
          name: r.name,
          status: 'active'
        }))
      } catch (error) {
        console.error('getTags: ', error)
        this.setErrorPage(error.code)
      } finally {
        this.loadingTags = false
      }
    },
    isShowTag (status) {
      return status === 'active'
    },
    unSelectTag (index) {
      const tagIndex = this.tags.findIndex((tag) => tag.name === this.tagsSelected[index])
      if (tagIndex !== -1) {
        this.tags[tagIndex].status = 'active'
        this.tagsSelected.splice(index, 1)
        this.searchProduct(true)
      }
    },
    selectTag (index) {
      this.tags[index].status = 'selected'
      this.tagsSelected.push(this.tags[index].name)
      this.searchProduct(true)
    },
    async fetchProducts () {
      this.getting = true
      if (this.page < this.totalPage) {
        this.page++
        try {
          const { data } = await productService.getProducts({
            page: this.page,
            limit: this.perPage,
            search: this.search,
            tags: this.tagsSelected,
            tagOperation: this.tagOperation
          })
          this.totalPage = data.pages
          this.totalProduct = data.total
          this.products.push(...data.results)
        } catch (error) {
          console.error('fetchProducts', error)
          this.setErrorPage(error.code)
        }
      }
      this.isLoadComplete = this.page >= this.totalPage
      this.getting = false
    },
    productSelecting (event) {
      this.$emit('productSelecting', event)
    }
  }
}
</script>

<style lang="scss" scoped>
.product-note-list {
  height: calc(100vh - 160px);
  overflow: auto;
}
</style>
